import type { HeadlineResponse } from '@on3/api';
import React, {
  Context,
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const ArticleContext: Context<IArticleContext> = createContext(
  {} as IArticleContext,
);

interface IArticleContext {
  article?: HeadlineResponse | null;
  setArticle: (
    value: SetStateAction<HeadlineResponse | null | undefined>,
  ) => void;
}
interface IArticleContextProps {
  article?: HeadlineResponse | null;
  children: React.ReactNode;
}

export const ArticleProvider = ({
  children,
  article: headline,
  ...props
}: IArticleContextProps) => {
  const [article, setArticle] = useState<HeadlineResponse | null | undefined>(
    headline,
  );

  useEffect(() => {
    setArticle(headline);
  }, [headline]);

  const contextValue = useMemo(
    () => ({
      article,
      setArticle,
    }),
    [article, setArticle],
  );

  return (
    <ArticleContext.Provider value={contextValue}>
      {children}
    </ArticleContext.Provider>
  );
};

const useArticle = () => {
  const context = useContext(ArticleContext);

  return context;
};

export { useArticle };

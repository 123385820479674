import type { IUser } from '@on3/ui-lib/api/schema/custom-contracts';

export const initialUser: IUser = {
  a: '',
  aud: '',
  exp: {
    date: 0,
  },
  has: false,
  iat: '',
  iss: '',
  n: '',
  pe: true,
  pl: '',
  st: '',
  rg: [],
  sub: '',
  uid: '',
  e: '',
};

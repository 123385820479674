import { Loader } from 'components/Svg/loader';

import styles from './Loading.module.scss';

export const Loading = () => (
  <div className={styles.spinner}>
    <Loader />
  </div>
);

export default Loading;

import type {
  CurrentSportYearRecord,
  MenuResponse,
  SiteResponse,
  SiteSubConfigRes,
  SportFilter,
} from '@on3/api';
import React, { Context, createContext, useContext, useMemo } from 'react';

const SiteContext: Context<ISiteContext> = createContext({} as ISiteContext);

export interface ISiteContext {
  siteUrls?: SiteResponse[];
  currentSite?: SiteResponse;
  menuData?: MenuResponse[];
  secondaryMenuData?: MenuResponse[];

  /** Valid sports */
  sportsData?: SportFilter[];
  rankingSports?: SportFilter[];
  defaultYears: CurrentSportYearRecord;
  subConfig?: SiteSubConfigRes | null;
  /** Type of Page */
  pageType?: string;
}

interface ISiteContextProps {
  siteUrls?: SiteResponse[];
  currentSite?: SiteResponse;
  menuData?: MenuResponse[];
  secondaryMenuData?: MenuResponse[];
  pageType?: string;
  sportsData?: SportFilter[];
  children: React.ReactNode;
  defaultYears?: CurrentSportYearRecord | null;
  subConfig?: SiteSubConfigRes | null;
}

export const SiteProvider = ({
  children,
  currentSite,
  menuData,
  secondaryMenuData,
  siteUrls,
  sportsData,
  pageType,
  defaultYears: incomingDefaultYears,
  subConfig,
}: ISiteContextProps) => {
  const defaultYears: CurrentSportYearRecord = useMemo(
    () => ({
      footballRecruitingYear:
        incomingDefaultYears?.footballRecruitingYear || 2024,
      nonFootballRecruitingYear:
        incomingDefaultYears?.nonFootballRecruitingYear || 2024,
      footballSeasonYear: incomingDefaultYears?.footballSeasonYear || 2023,
      nonFootballSeasonYear:
        incomingDefaultYears?.nonFootballSeasonYear || 2023,
      transferYear: incomingDefaultYears?.transferYear || 2023,
      nbaDraftYear: incomingDefaultYears?.nbaDraftYear || 2023,
      nflDraftYear: incomingDefaultYears?.nflDraftYear || 2023,
      nilYear: incomingDefaultYears?.nilYear || 2023,
    }),
    [
      incomingDefaultYears?.footballRecruitingYear,
      incomingDefaultYears?.footballSeasonYear,
      incomingDefaultYears?.nbaDraftYear,
      incomingDefaultYears?.nflDraftYear,
      incomingDefaultYears?.nilYear,
      incomingDefaultYears?.nonFootballRecruitingYear,
      incomingDefaultYears?.nonFootballSeasonYear,
      incomingDefaultYears?.transferYear,
    ],
  );
  const contextValue = useMemo(
    () => ({
      currentSite,
      menuData,
      secondaryMenuData,
      siteUrls,
      sportsData,
      pageType,
      defaultYears,
      subConfig,
    }),
    [
      currentSite,
      menuData,
      secondaryMenuData,
      siteUrls,
      sportsData,
      pageType,
      defaultYears,
      subConfig,
    ],
  );

  return (
    <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>
  );
};

const useSite = () => {
  const context = useContext(SiteContext);

  return context;
};

export { useSite };

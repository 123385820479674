import React, { createContext, useContext } from 'react';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';

const notify = (text: string, options?: ToastOptions) => toast(text, options);

const contextState = {
  error: toast.error,
  info: toast.info,
  notify,
  success: toast.success,
  warning: toast.warning,
};

interface ComponentProps {
  children: React.ReactNode;
}

const ToastContext = createContext(contextState);

export const ToastProvider = ({ children }: ComponentProps) => {
  return (
    <ToastContext.Provider value={contextState}>
      {children}
      <ToastContainer
        autoClose={7000}
        className="toast-container"
        closeOnClick={false}
        draggable
        hideProgressBar={true}
        pauseOnFocusLoss
        pauseOnHover
        position="bottom-left"
        progressClassName="toast-bar"
        toastClassName="toast"
      />
    </ToastContext.Provider>
  );
};

function useToast() {
  const context = useContext(ToastContext);

  return context;
}

export { useToast };

import 'styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import type { HeadlineResponse, SiteResponse } from '@on3/api';
import { AuthProvider, ToastProvider } from '@on3/ui-lib';
import { IUser } from '@on3/ui-lib/api/schema/custom-contracts';
import { hashEmail } from '@on3/ui-lib/utils/user';
import { Loading } from 'components/UI/Loading/Loading';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createEmotionCache } from 'styles/createEmotionCache';
import on3Theme, { initTheme } from 'styles/theme';

const clientSideEmotionCache = createEmotionCache();

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
  }
}

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const pushToDataLayer = async (
  siteData: SiteResponse,
  userData: Partial<IUser>,
  articleData: HeadlineResponse,
  pageType: string,
) => {
  const siteType = siteData?.isTeam
    ? 'team'
    : siteData?.isChannel
      ? 'channel'
      : 'network';

  let encodedEmail: string | null = null;

  if (userData?.e) {
    try {
      encodedEmail = await hashEmail(userData?.e);
    } catch {
      encodedEmail = null;
    }
  }

  const teamName = siteData?.slug === '/' ? null : siteData?.slug?.toString();

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'page_view',
    userStatus: `${userData?.st?.toString() || null}`,
    userKey: `${userData?.uid || null}`,
    authorName: articleData?.author?.name?.toString() || null,
    authorId: articleData?.author?.key?.toString() || null,
    contentId: articleData?.key?.toString() || null,
    siteType: `${siteType}`,
    teamName,
    siteName: `${siteData?.siteName?.toString()}`,
    pageType: pageType || 'other',
    email: encodedEmail,
  });
};

export const MyApp = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const [isLoading, setIsLoading] = useState(false);
  const pathname = usePathname();
  const router = useRouter();
  const { asPath } = router;

  const {
    primaryColor,
    secondaryColor,
    linkColor,
    key,
    isChannel,
  }: SiteResponse = pageProps?.siteData?.currentSite || {};

  const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'production';
  const isTransferPortal = key === 369;

  const removeHtmlCustomStyle = () => {
    document.documentElement.removeAttribute('style');
    document.body.removeAttribute('style');
  };

  const handleStart = useCallback(
    (url: string) => {
      if (url !== asPath) {
        removeHtmlCustomStyle();
        setIsLoading(true);
      }
    },
    [asPath],
  );

  const handleComplete = useCallback(() => {
    setIsLoading(false);
    removeHtmlCustomStyle();
  }, []);

  const teamTheme = useMemo(
    () =>
      initTheme(
        {
          palette: {
            primary: { main: linkColor || primaryColor || '#fe3b1f' },
            secondary: { main: secondaryColor || '#eef1f9' },
          },
          appHeader: {
            backgroundImage:
              key === 24
                ? 'https://on3static.com/static/ksr/ksr-header.png'
                : '',
            backgroundColor:
              isChannel && !isTransferPortal
                ? '#fff'
                : primaryColor || '#3b4147',
          },
        },
        on3Theme,
      ),
    [linkColor, primaryColor, secondaryColor, key, isChannel, isTransferPortal],
  );

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('hashChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('hashChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [handleComplete, handleStart, router]);

  useEffect(() => {
    const site = pageProps?.siteData?.currentSite as SiteResponse;
    const userData = pageProps?.userData as Partial<IUser>;
    const articleData = pageProps?.article as HeadlineResponse;
    const pageType = pageProps?.pageType as string;

    pushToDataLayer(site, userData, articleData, pageType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const initialDataLayerValues = JSON.stringify([
    {
      siteKey: pageProps?.siteData?.currentSite?.key || '',
      userStatus: pageProps?.userData?.st || 'guest',
      userKey: pageProps.userData?.uid || null,
      authorName: pageProps?.article?.author?.name?.toString() || null,
      authorId: pageProps?.article?.author?.key?.toString() || null,
      contentId: pageProps.article?.key?.toString() || null,
      siteType: pageProps?.siteData?.currentSite?.isTeam
        ? 'team'
        : pageProps?.siteData?.currentSite?.isChannel
          ? 'channel'
          : 'network',
      teamName:
        pageProps?.siteData?.currentSite?.slug === '/'
          ? null
          : pageProps?.siteData?.currentSite?.slug.toString(),
      siteName: pageProps?.siteData?.currentSite?.siteName || '',
      pageType: pageProps?.pageType || 'other',
    },
  ]);

  return (
    <StylesProvider injectFirst>
      <ToastProvider {...pageProps}>
        <AuthProvider userData={pageProps?.userData}>
          <Head>
            <meta content={primaryColor || '#fe3b1f'} name="theme-color" />
            <meta charSet="utf-8" />
            <link href="/favicon.ico" rel="icon" />
            <meta content="386430152433689" property="fb:app_id" />
            <meta
              content="hwer35nvdlw6s5jyhja27j153jth48"
              name="facebook-domain-verification"
            />
            {/* TODO: add manifest json */}
            <link
              href="https://on3static.com/static/on3/touch-icons/favicon.ico"
              rel="icon"
              type="image/x-icon"
            />
            <link
              href="https://on3static.com/static/on3/touch-icons/apple-touch-icon.png"
              rel="apple-touch-icon"
            />
            <link
              href="https://on3static.com/static/on3/touch-icons/apple-touch-icon-57x57.png"
              rel="apple-touch-icon"
              sizes="57x57"
            />
            <link
              href="https://on3static.com/static/on3/touch-icons/apple-touch-icon-72x72.png"
              rel="apple-touch-icon"
              sizes="72x72"
            />
            <link
              href="https://on3static.com/static/on3/touch-icons/apple-touch-icon-76x76.png"
              rel="apple-touch-icon"
              sizes="76x76"
            />
            <link
              href="https://on3static.com/static/on3/touch-icons/apple-touch-icon-114x114.png"
              rel="apple-touch-icon"
              sizes="114x114"
            />
            <link
              href="https://on3static.com/static/on3/touch-icons/apple-touch-icon-120x120.png"
              rel="apple-touch-icon"
              sizes="120x120"
            />
            <link
              href="https://on3static.com/static/on3/touch-icons/apple-touch-icon-144x144.png"
              rel="apple-touch-icon"
              sizes="144x144"
            />
            <link
              href="https://on3static.com/static/on3/touch-icons/apple-touch-icon-152x152.png"
              rel="apple-touch-icon"
              sizes="152x152"
            />
            <link
              href="https://on3static.com/static/on3/touch-icons/apple-touch-icon-180x180.png"
              rel="apple-touch-icon"
              sizes="180x180"
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer = ${initialDataLayerValues} || window.dataLayer;
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-M7CD4SPX');
                `,
              }}
            />
            {!isProd && <meta content="noindex, nofollow" name="robots" />}
          </Head>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={teamTheme}>
              <CssBaseline />
              {isLoading && <Loading />}
              <Component {...pageProps} />
              {/* Google Tag Manager iframe for noscript */}
              <noscript>
                <iframe
                  height="0"
                  src="https://www.googletagmanager.com/ns.html?id=GTM-M7CD4SPX"
                  style={{ display: 'none', visibility: 'hidden' }}
                  title="google tag manager"
                  width="0"
                />
              </noscript>
            </ThemeProvider>
          </CacheProvider>
        </AuthProvider>
      </ToastProvider>
    </StylesProvider>
  );
};

export default MyApp;

export const editorRoles = [
  {
    role: 'alabama_editor', // BamaOnLine - Alabama
    site: [72],
  },
  {
    role: 'auburn_editor', // Auburn Live - Auburn
    site: [76],
  },
  {
    role: 'notre_dame_editor', // Blue and Gold - Notre Dame
    site: [363],
  },
  {
    role: 'wearesc_editor', // WeAreSC - USC
    site: [79],
  },
  {
    role: 'insidetexas_editor', // InsideTexas - Texas
    site: [73],
  },
  {
    role: 'scoop_duck_editor', // Scoop Duck - Oregon
    site: [75],
  },
  {
    role: 'ksr_editor', // KSR - Kentucky
    site: [24, 395],
  },
  {
    role: 'lettermen_row_editor', // Lettermen Row - Ohio State
    site: [25],
  },
  {
    role: 'clemson_sports_editor', // Clemson Sports - Clemson
    site: [9],
  },
  {
    role: 'dawgshq_editor', // DawgsHQ - Georgia
    site: [74],
  },
  {
    role: 'florida_editor', // GatorsOnline - Florida
    site: [83],
  },
  {
    role: 'lsu_editor', // The Bengal Tiger - LSU
    site: [364],
  },
  {
    role: 'miami_editor', // Cane Sports - Miami
    site: [371],
  },
  {
    role: 'michigan_editor', // The Wolverine - Michigan
    site: [80],
  },
  {
    role: 'nc_state_editor', // The Wolfpacker - NC State
    site: [82],
  },
  {
    role: 'nebraska_editor', // HuskerOnline - Nebraska
    site: [370],
  },

  {
    role: 'ole_miss_editor', // OM Spirit - OleMiss
    site: [362],
  },
  {
    role: 'penn_state_editor', // BWI - Penn State
    site: [81],
  },
  {
    role: 'smu_editor', // On The Pony Express - SMU
    site: [365],
  },
  {
    role: 'south_carolina_editor', // GamecockCentral - South Carolina
    site: [366],
  },
  {
    role: 'texas_am_editor', // Texas AM
    site: [367],
  },
  {
    role: 'fsu_editor', // Warchant - FSU
    site: [372],
  },
  {
    role: 'tennessee_editor', // Volquest - Tennessee
    site: [373],
  },
  {
    role: 'kansas_state_editor', // K-State Online - Kansas State
    site: [374],
  },
  {
    role: 'purdue_editor', // Gold and Black - Purdue
    site: [377],
  },
  {
    role: 'michigan_state_editor', // SpartanMag - Michigan State
    site: [382],
  },
  {
    role: 'iowa_editor', // Hawkeye Report - Iowa
    site: [383],
  },
  {
    role: 'oklahoma_editor', // Sooner Scoop - Oklahoma
    site: [396],
  },
  {
    role: 'mississippi_state_editor', // Maroon and white - Mississippi State
    site: [376],
  },
];

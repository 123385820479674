import { createTheme, PaletteOptions, Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const on3Palette = {
  primary: { main: '#fe3b1f' }, //redorange,
  secondary: { main: '#eef1f9' }, //lightGray,
  error: { main: '#ff5252' }, //red
  background: { default: '#fff' }, //container
  success: { main: '#70db6a', dark: '#70ED69' }, //premiumGreen
};

interface AdjustedPalette {
  main?: string;
}

export const initTheme: (
  options: {
    palette?: PaletteOptions;
    appHeader?: {
      backgroundImage?: string;
      backgroundColor?: string;
      type?: string;
    };
  },
  parentTheme?: Theme,
) => Theme = (options, parentTheme) => {
  const primaryColor =
    (options?.palette?.primary as AdjustedPalette)?.main ||
    on3Palette.primary.main;

  return createTheme({
    palette: options.palette || on3Palette,
    appHeader: {
      ...parentTheme?.appHeader,
      backgroundColor:
        options.appHeader?.backgroundColor || on3Palette.primary.main,
      backgroundImage: options.appHeader?.backgroundImage,
      type: options.appHeader?.type || 'Feed',
    },
    typography: {
      fontFamily: ['Geist', 'Merriweather', 'Arial', 'sans-serif'].join(','),

      h1: {
        fontFamily: 'Geist',
        fontSize: '24px',
        fontWeight: 900,
        lineHeight: 1.375,
        letterSpacing: 0.25,
      },
      h2: {
        fontFamily: 'Geist',
        fontSize: '18px',
        fontWeight: 900,
        lineHeight: 1.5,
        letterSpacing: 0.15,
      },
      h3: {
        fontFamily: 'Geist',
        fontSize: '13px',
        fontWeight: 900,
        lineHeight: 1.5,
        letterSpacing: 0.15,
      },
      h4: {
        fontFamily: 'Geist',
        fontSize: '12px',
        fontWeight: 900,
        lineHeight: 1,
        letterSpacing: 0.15,
      },
      h5: {
        fontFamily: 'Archivo Narrow',
        fontSize: '15px',
        fontWeight: 600,
        fontStyle: 'italic',
        lineHeight: 1.125,
        letterSpacing: 0.15,
      },
      h6: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 0.15,
      },
      //Tag
      subtitle1: {
        fontFamily: 'Archivo Narrow',
        fontSize: '11px',
        fontWeight: 600,
        lineHeight: 0.75,
        letterSpacing: 0.15,
        textTransform: 'uppercase',
      },
      subtitle2: {
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 0.15,
      },
      body1: {
        fontSize: '15px',
        letterSpacing: 0.65,
      },
      body2: {
        fontFamily: 'Merriweather',
        fontWeight: 400,
        fontSize: '19px',
        lineHeight: 1.688,
        letterSpacing: 0.25,
      },
      overline: {
        fontSize: '12px',
        lineHeight: 1.188,
        letterSpacing: 4.15,
      },
      caption: {
        fontSize: '12px',
        lineHeight: 1,
        letterSpacing: 0.15,
      },
      button: {
        fontFamily: 'Geist',
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
            height: '100%',
          },
        },
        variants: [
          {
            props: { variant: 'contained', color: 'primary' },
            style: {
              border: '1px solid',
              borderColor: primaryColor,
              '&:hover': {
                backgroundColor: on3Palette.background.default,
                color: primaryColor,
              },
            },
          },
          {
            props: { variant: 'outlined' },
            style: {
              transition: '0.25s all ease',
              '&:hover': {
                backgroundColor: on3Palette.background.default,
                color: primaryColor,
                border: '1px solid',
                borderColor: primaryColor,
              },
            },
          },
          {
            props: { variant: 'outlined', color: 'primary' },
            style: {
              '&:hover': {
                backgroundColor: on3Palette.background.default,
                color: primaryColor,
                border: '1px solid',
                borderColor: primaryColor,
              },
            },
          },
          {
            props: { variant: 'outlined', color: 'secondary' },
            style: {
              '&:hover': {
                backgroundColor: on3Palette.background.default,
                color: primaryColor,
                border: '1px solid',
                borderColor: primaryColor,
              },
            },
          },
        ],
      },
      MuiCheckbox: {
        styleOverrides: {
          colorSecondary: {
            '&.Mui-checked': {
              color: '#2196F3',
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          colorSecondary: {
            '&.Mui-checked': {
              color: '#FE3B1F',
            },
          },
          track: {
            '&.Mui-checked + &': {
              // Controls checked color for the track
              opacity: 0.7,
              backgroundColor: '#F4EFDC',
            },
          },
        },
      },
    },
  });
};

export const on3Theme = initTheme({ palette: on3Palette });

export default on3Theme;
